import { httpClient } from '@/app/shared/services';

export const PICKER_DOCUMENTS_STORE_MODULE_NAME = 'picker-documents';

export const pickerDocumentsStoreModule = {
  namespaced: true,
  state: {
    data: [],
    meta: {},
  },
  getters: {},
  mutations: {
    SET_PICKER_DOCUMENTS_DATA(state, payload) {
      state.data = payload;
    },
    SET_PICKER_DOCUMENTS_META(state, payload) {
      state.meta = payload;
    },
  },
  actions: {
    fetchPickerDocumnets({ commit }) {
      return new Promise((res, rej) => {
        httpClient
          .get('/pickers/files')
          .then((response) => {
            const { data, meta } = response.data;
            commit('SET_PICKER_DOCUMENTS_DATA', data);
            commit('SET_PICKER_DOCUMENTS_META', meta);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    generatePickerDocuments({ dispatch }, { preview, date }) {
      return new Promise((res, rej) => {
        httpClient
          .get(`/pickers/file`, { params: { preview, date } })
          .then((response) => {
            if (!preview) {
              dispatch('fetchPickerDocumnets');
            }
            res(response.data);
          })
          .catch((error) => rej(error));
      });
    },
    deleteDocument(_, { id }) {
      return new Promise((res, rej) => {
        httpClient
          .delete(`/pickers/file/${id}`)
          .then((response) => {
            res(response.data);
          })
          .catch((error) => rej(error));
      });
    },
  },
};
