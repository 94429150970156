import { ref } from '@vue/composition-api';

export function useDocumentsPreview() {
  const isOpen = ref(false);
  const url = ref('');

  const open = (uri) => {
    url.value = `https://view.officeapps.live.com/op/embed.aspx?src=${uri}`;
    isOpen.value = true;
  };

  const closeDialog = () => {
    isOpen.value = false;
  };

  return {
    isOpen,
    open,
    closeDialog,
    url,
  };
}
