<template>
  <el-dialog :visible.sync="isOpen" fullscreen>
    <iframe class="iframe" :src="url" frameborder="0" />
  </el-dialog>
</template>
<script>
import { useDocumentsPreview } from './useDocumentsPreview';

export default {
  setup() {
    const { isOpen, open, closeDialog, url } = useDocumentsPreview();

    return {
      isOpen,
      open,
      closeDialog,
      url,
    };
  },
};
</script>
<style lang="scss" scoped>
.iframe {
  height: 90vh;
  width: 97vw;
}
</style>
